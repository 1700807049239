@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: 'Placard Next';
        src:
            url('./fonts/placard-next-medium.woff2') format('woff2'),
            url('./fonts/placard-next-medium.woff') format('woff');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }
}
